import React from 'react'
import '../../index.css'
import ParticlesBackground from './../Home/ParticlesBackground';
import { Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom';

const Main = () => {
    return (
        <div className="min-h-screen bg-[#002585] flex flex-col justify-center items-center">
            <ParticlesBackground styles={false} />

            <div className='absolute top-4 left-4'>
                <img
                    // src="https://res.cloudinary.com/ddffeufbq/image/upload/v1705655271/ursmailer/logo_zkgyxk.png"
                    src="https://ursmailer.solutions/images/logo.svg"
                    className='w-11/12 md:w-[24rem] mix-blend-lighten' alt="" />
            </div>

            <div className='grid w-full place-items-center py-4 z-10 text-[#015a73] px-4 mt-12'>
                <h1 className={`font-[Poppins]  text-4xl sm:text-5xl font-[700] text-white text-center leading-none tracking-wide`}>
                    Your Gateway to
                    {" "}
                    <span className='bg-gradient-to-r from-[#fca311] to-[#fca311] font-[700] bg-clip-text text-transparent'>
                        Cost-Effective
                    </span>
                    {" "}
                    Excellence in Bulk <br className='hidden md:block' /> Emailing and
                    {" "}
                    <span className='bg-gradient-to-r from-[#fca311] to-[#fca311] font-[700] bg-clip-text text-transparent'>
                        HTML email Marketing.
                    </span>
                </h1>
                <h3 className={`text-normal text-center text-white mt-2 font-[Poppins] `}>
                    {/* text-muted-foreground */}
                    Explore unparalleled affordability and deliverability with URSmailer.solutions, where each email costs less than 0.005 paisa, ensuring prime inbox <br className='hidden md:block' /> placement for your marketing success.
                </h3>

                <Link className='font-[Poppins] py-2 mt-8' to='/login'>
                    <Button bg="#fca311" color="white" _hover={{ bg: '#fcc911' }} borderColor='#fca311'
                        _active={{
                            bg: '#fcc911',
                            transform: 'scale(0.98)',
                            borderColor: '#fcc911',
                        }}
                        _focus={{
                            boxShadow:
                                'none',
                        }}
                    >
                        Click here to Start!
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default Main