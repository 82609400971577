import Particles from "react-tsparticles";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import particlesConfig from "./config/particles-config";
import config2 from "./config/config2";

const ParticlesBackground = ({styles = true}) => {
    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
    }, []);

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={styles ? particlesConfig : config2}
        />
    )
}

export default ParticlesBackground
