import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { apiConnector } from '../../apiConnector';
import Navbar from '../../components/Navbar/Navbar'
import Loader from './../../components/Loader/Loader';
import { url } from './../../api';
import { toast } from 'react-hot-toast';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai'
import ClipboardJS from 'clipboard';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Uploads = () => {
  const [loading, setLoading] = useState(false)
  const fileInputRef = useRef();
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = e.target.files;
    setSelectedImages([...selectedImages, ...files]);
  };

  const navigate = useNavigate();
  const admin = useSelector((state) => state.admin);

  useEffect(() => {
    if (!admin._id) {
      navigate("/login");
    }
  }, [navigate, admin]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    try {
      const formData = new FormData();
      selectedImages.forEach((image) => {
        formData.append('images', image);
      });

      let response = await apiConnector({
        method: "POST",
        url: `${url}/api/v1/uploadImages`,
        bodyData: formData,
      });
      if (response.data.success) {
        toast.success(response?.data?.message)
      }
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error);
      }
    }
    setSelectedImages([]);
    fileInputRef.current.value = '';
    setLoading(false);
    getData();
  }

  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1)
  const [totalPages, setTotalPages] = useState();

  const getData = async (pageNo = 1, pageSize = 10) => {
    window.scroll(0, 0);
    try {
      const response = await apiConnector({ method: "GET", url: url + `/api/v1/findAllImgUrlModelObject?pageNo=${pageNo}&pageSize=${pageSize}` })
      const urls = response.data.data.map(obj => obj.urls).flat();
      setData(urls)
      setTotalPages(Math.ceil(response.data.count / pageSize))
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getData();
  }, [])

  const increasePageNo = (pageNo) => {
    if (pageNo < totalPages && pageNo > 0) {
      setPageNo(pageNo = pageNo + 1)
      getData(pageNo)
    }
  }

  const descreasePageNo = (pageNo) => {
    if (pageNo !== 0 && pageNo > 0 && pageNo !== 1) {
      setPageNo(pageNo = pageNo - 1)
      getData(pageNo)
    }
  }

  const handleClick = (item) => {
    const clipboard = new ClipboardJS('.copy-button', {
      text: () => item,
    });

    clipboard.on('success', (e) => {
      toast.success(`copied to clipboard`);
      clipboard.destroy();
    });

    clipboard.on('error', (e) => {
      toast.error('Unable to copy text to clipboard', e);
      clipboard.destroy();
    });

    clipboard.onClick({ currentTarget: document.getElementById('copy-button') });
  };


  return (
    <div>
      <Navbar />

      {/* ḥeader component */}
      <div
        className="relative overflow-hidden bg-transparent"
        style={{ height: '120px' }}>
      </div>

      {/* Upload IMages Form */}
      <div className='bg-white rounded-xl w-full text-gray-900 flex justify-center items-center px-4'>
        <form onSubmit={handleSubmit} className='w-full md:w-[60%]'>
          <h2 className='text-2xl font-[Poppins] text-center text-gray-900 font-[500] pb-4 tracking-wide'>Upload Image</h2>

          <div className='mb-4 flex flex-col'>
            <input
              className="block w-full  font-[Poppins] text-sm text-gray-900 border border-blue-700 rounded-lg cursor-pointer bg-gray-50  focus:outline-none"
              type="file"
              id="formFileMultiple"
              accept='image/*'
              multiple
              onChange={handleImageChange}
              ref={fileInputRef}
            ></input>
          </div>


          <div className='mb-4 mt-8 flex flex-col relative'>
            <button type='submit' className='border-2 border-blue-700 bg-blue-700 text-white rounded-lg mx-auto px-4 py-1 text-sm font-[Poppins] hover:bg-blue-700 duration-100 transition-all flex justify-center items-center tracking-wider'>
              {
                loading ? <Loader /> :
                  <>
                    <FaCloudUploadAlt className='mr-2' />Upload
                  </>
              }
            </button>
          </div>
        </form>
      </div>

      {/* display all images */}
      <div className='mt-6 md:mt-12 px-4'>
        <h2 className='text-2xl font-[Poppins] text-center text-gray-900 font-[500] pb-4 tracking-wide'>Available Images</h2>

        {
          data?.length > 0 ?
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 place-items-center gap-8 py-6'>
              {
                data?.map((item, index) => (
                  <div key={index} className="h-[300px] group w-[300px] relative border border-blue-700 p-1 rounded-lg">

                    <div className='copy-button  px-2 py-1 border border-blue-700 text-white bg-blue-700  absolute right-2 top-2 rounded-full p-1 flex justify-center items-center cursor-pointer opacity-0 duration-300 group-hover:opacity-100 text-sm'
                      id="copy-button" onClick={() => handleClick(item)}
                    >
                      {/* <FaCode className='text-lg ' /> */}
                      Copy
                    </div>
                    <img src={item} className="h-full w-full object-contain" alt="" />

                  </div>
                ))
              }
            </div>
            :
            <>
              <h2 className='text-2xl font-[Poppins] text-center text-red-600 font-[500] pb-4 tracking-wide'>No Images Found</h2>
            </>
        }

      </div>


      <div className='w-full h-full bg-gray-500 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10'>
        <div className='w-[90%] mx-auto flex justify-between items-center px-4 py-4 '>
          <div className='flex justify-between items-center gap-6'>
            <button className='bg-blue-700 text-white text-sm px-4 py-1.5 rounded-md' onClick={() => descreasePageNo(pageNo)}>
              <p className='font-[Poppins] font-[400] flex justify-center items-center'><AiOutlineLeft className='mr-2' />Prev </p>
            </button>
            <button className='bg-blue-700 text-white text-sm px-4 py-1.5 rounded-md' onClick={() => increasePageNo(pageNo)}>
              <p className='font-[Poppins] font-[400] flex justify-center items-center'>Next <AiOutlineRight className='ml-2' /></p>
            </button>
          </div>
          <div >
            <p className='bg-gray-100  text-gray-900 font-[Poppins]'>{pageNo} of {totalPages}</p>
          </div>
        </div>
      </div>
  
    </div>
  )
}

export default Uploads
